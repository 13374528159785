<template>
  <el-dialog
    title="详情"
    :visible.sync="dialogVisible"
    width="50%"
    :before-close="handleClose"
  >
    <div>
      <el-descriptions class="margin-top" :column="2" size="small" border>
        <el-descriptions-item>
          <template slot="label">
            ID
          </template>
          <div>
            {{ form.id }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            用户名
          </template>
          <div>
            {{ form.screen_name }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            昵称
          </template>
          <div>
            {{ form.name }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            备注
          </template>
          <div>
            {{ form.remark }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            登录帐号
          </template>
          <div>
            {{ form.login_account }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            主页链接
          </template>
          <div>{{ form.index_url }}</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            状态
          </template>
          <div>
            <el-tag
              :type="getStatusType(form.status, statusMap)"
              disable-transitions
              size="mini"
            >
              {{ getStatusLabel(form.status, statusMap) }}
            </el-tag>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            风险
          </template>
          <div>
            <el-tag
              :type="form.is_risk ? 'danger' : 'success'"
              disable-transitions
              size="mini"
            >
              {{ form.is_risk ? '有风险' : '无风险' }}
            </el-tag>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            演示帐号
          </template>
          <div>
            <el-switch
              v-model="form.is_test"
              active-color="#13ce66"
              inactive-color="#ff4949"
              disabled
              slot="reference"
            ></el-switch>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            分组
          </template>
          <div>
            <el-tag
              type="warning"
              disable-transitions
              size="mini"
              v-if="form.group_entity && form.group_entity.name"
            >
              {{ form.group_entity.name }}
            </el-tag>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            标签
          </template>
          <div v-if="form.tags_entity">
            <el-tag
              style="margin: 0 5px 5px 0;"
              disable-transitions
              size="mini"
              v-for="(item, i) in form.tags_entity"
              :key="i"
            >
              {{ item.tag }}
            </el-tag>
          </div>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            使用者
          </template>
          <div>
            {{
              form.user_entity
                ? form.user_entity.oauth_account
                  ? form.user_entity.oauth_account
                  : form.user_entity.username
                : null
            }}
          </div>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            最后登录时间
          </template>
          <div>
            {{ form.last_login_time }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            使用者备注
          </template>
          <div>
            {{ form.user_entity ? form.user_entity.note : null }}
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      statusMap: {
        1: {
          type: 'success',
          label: '正常'
        },
        0: {
          type: 'warning',
          label: '异常'
        },
        '-1': {
          type: 'danger',
          label: '失效',
          tip: '该账号因为异常行为已被暂停使用'
        }
      }
    }
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,
    initFun(row) {
      console.log(row)
      this.form = { ...row }
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-descriptions-item__label {
  width: 100px !important;
}
::v-deep .el-descriptions-item__content {
  width: 300px !important;
}
</style>
