<template>
  <el-dialog
    title="标记"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="标签" prop="list">
          <el-select
            style="width: 100%;"
            v-model="form.list"
            placeholder="请选择标签"
            filterable
            clearable
            multiple
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.tag"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitFun">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { all_tagApi } from '@/api/mark/index'
import { batchTagApi } from '@/api/mark/facebook'
export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        list: [
          {
            required: true,
            message: '请选择标签',
            trigger: 'change'
          }
        ]
      },
      options: []
    }
  },
  created() {},
  methods: {
    initFun(facebook_account_id, arr) {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.form = { facebook_account_id: facebook_account_id }
      if (arr) {
        this.$set(this.form, 'list', [])
        this.form.list = arr
      }
      this.dialogVisible = true
      this.getOptions()
    },
    handleClose() {
      this.dialogVisible = false
    },
    submitFun() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let str = this.form.list.join(',')
          batchTagApi(this.form.facebook_account_id, {
            ids: this.form.list
          }).then(res => {
            this.$message.success('标记成功！')
            this.dialogVisible = false
            this.$emit('updateFun')
          })
        }
      })
    },
    getOptions() {
      all_tagApi().then(data => {
        this.options = data
      })
    }
  }
}
</script>
